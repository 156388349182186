.input {
    outline: none !important;
    border: none;
    box-shadow: none;
}

.input:focus {
    outline: none !important;
    border: none;
    box-shadow: none;
}

