.heading{
    color : #5f5f5f;
    font-size: 60px;
    text-align: center;
    font-family: AvenirMedium, sans-serif;
    padding-top: 55px;
}
.main{
    text-align: center;
}
.date{    
    font-family: AvenirBlack, serif;
    font-size: 64px;
    
    color : #F1BE1B;
}
.letters{
    font-family: AvenirMedium, sans-serif;
    font-size: 44px;
    color : #F1BE1B;
}
.next{
    font-family: AvenirMedium, sans-serif;
    font-size: 39px;
    color : #5f5f5f;
}
.App {
    font-family: sans-serif;
    text-align: center;
}
  
html,body{
    width:100%;
    height:100%;
    margin:0px;
    padding:0px;
    overflow-x: hidden;
    
    }